import { createApp } from 'vue'
import DatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import App from './App.vue'
import {langMethods}  from './mixins/langConxtant.js'
import Axios from 'axios'
import vuetify from './plugins/vuetify'
import Vuex from 'vuex';
import { loadFonts } from './plugins/webfontloader'
import excel from "vue-excel-export";
import router from './router'
import store from './store'
const app = createApp(App)


app.config.globalProperties.$http = Axios;
const token = localStorage.getItem('token')
if (token) {
  app.config.globalProperties.$http.defaults.headers.common['Authorization'] = token
}
app.component('DatePicker', DatePicker);
//import VueSocketIO from 'vue-3-socket.io'


/*import io from 'socket.io-client';
const socketio = new VueSocketIO({
    debug: true,
    connection: io('ws://91.221.67.104:1337',{ transports : ['websocket'] }),
	// http: ourselves: port
    extraHeaders: {"Access-Control-Allow-Origin": '*'},
});*/



loadFonts()
Axios.interceptors.response.use(

error => {
  if(error.response){
 
  if (error.response.status === 401||error.response.status === 403) {
    window.location.href = '/login';
  }
  if (error.response.status === 400) {
    store.commit('setSnackbar',{data:error.response.data.error.details.Error, color:"red"})
   
  }
  if (error.response.status === 500||error.response.status === 502) {
    store.commit('setSnackbar',{data:langMethods.methods.getLangConst('500'), color:"red"})
    return
  }
  if(error.toJSON().message === 'Network Error'){
    store.commit('setSnackbar',{data:'Service unavailable, please try again later'})
    
  }
}
 return error
},  response =>{

  return response
},
);

app.mixin(langMethods)
//app.mixin(encode)
app.use(router)
app.use(vuetify)
app.use(excel);
app.use(router)
app.use(store)
app.use(Vuex)
//  .use(socketio)
app.mount('#app')
