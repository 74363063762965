/* eslint-disable */
/* eslint-disable */

import Vuex from 'vuex';
import axios from 'axios';
import router from '@/router'

import {langMethods}  from '@/mixins/langConxtant.js'
const apiURL = document.location.host.indexOf('test')==-1&&document.location.host.indexOf('localhost')==-1?process.env.VUE_APP_SERVER_URL:process.env.VUE_APP_TESTSERVER_URL

export default new Vuex.Store({
  state: {
    apiURL : apiURL,
    snackbar:{
      show:false,
      text:'',
      color:''
    },
    status: '',
    token: localStorage.getItem('token') || null,
    userID:localStorage.getItem('userID') ||null,
    user : {},
    role:localStorage.getItem('role') ||null,
    located:localStorage.getItem('located')||'ru',

    scripts:[],
    lessons:[],
    lessonData:{},
    students:[],
    reportData:{},
    currentDialogID: null,
    script:{
      id:null,
      newDialogID:null,
      lastDialogID:null,
      name:"",
      desc:"",
      createDate:"",
      filteredPlainText:[],
      dialogs:[
          {
          id:1,
          attributes:{
            answers:[],
            dialog:"",
            dialog_id:null
          }
        }
      ],
      plainText:[
        {
          type:"",
          id:null,
          text:"",
          dID:null,
          nextDialogId : null,
          elemID : null
        }
      ],
      dialogsOnly:[
        {
          type:"",
          id:null,
          text:"",
          dID:null,
          nextDialogId : null,
          elemID : null
        }
      ],
      searchIDs:[],
    }
  },
  getters : {
    isLoggedIn: state => !!state.token,
    getRole: state=> state.role,
    getLessons:state =>state.lessons,
    getLessonData:state =>state.lessonData,
    getLocated:state=>state.located,
    snackbar:state =>state.snackbar,
    token: state => state.token,
    authStatus: state => state.status,
    scripts: state => state.scripts,
    students: state => state.students,
    script: state => state.script,
    scriptTypes:state=>state.scriptTypes,
    filteredPlainText: state => state.script.filteredPlainText,
    plainText: state => state.script.plainText,
    dialogsOnly: state => state.script.dialogsOnly,
    searchIDs: state => state.script.searchIDs,
    mapKey: state => state.mapKey,
    apiURL : state => state.apiURL,
    reportData  : state => state.reportData,
  },
  mutations: {
    auth_request(state){
      state.status = 'loading'
    },
    setRole(state,data){
      localStorage.setItem('role', data)
      state.role=data
    },
    setReport(state,data){
      state.reportData=data
    },
    setLessons(state,data){
      state.lessons=data
    },
    setLessonData(state,data){
      state.lessonData=data
    },
    setSnackbar(state,{data,color}){
      state.snackbar.show = true
      state.snackbar.text = data
      state.snackbar.color = color
    },
    setScriptTypes(state,data){
      state.scriptTypes=data
    },
    setDialogID(state,id){
      
      state.script.lastDialogID=id
    },
    setStudents(state,data){
      state.students=data

    },
    setScripts(state,data){
      state.scripts=data

    },
    setScript(state,data){
      state.script.id=data.id
      state.script.name=data.attributes.name
      state.script.desc=data.attributes.description
      state.script.createDate=data.attributes.createDate
    },
    setSearchIDs(state,data){
      
      state.script.searchIDs=data
    },
    setfilteredPlainText(state,data){
      state.script.filteredPlainText=data
    },
    initSuccess(state, data){
      state.status = 'success'
      state.token=data.token
      state.located=data.locale
    },
   
    
    auth_success(state, data){
      state.status = 'success'
      state.token = 'Bearer '+data.jwt
      state.user = data.user

    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
    },
    searchResultPositions(state,data){
      state.searchResultPositions = data;
    },
    searchResultPositionsCounter(state,data){
      state.searchResultPositionsCounter = data;
    }
  },
  actions: {
    login({commit}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({url: apiURL + '/api/auth/local', data: user, method: 'POST' })
        .then(resp => {
          const token = 'Bearer '+resp.data.jwt
          const user = resp.data.user
          localStorage.setItem('token', token)
          localStorage.setItem('userID', user.id)
          axios.defaults.headers.common['Authorization'] = token
          commit('auth_success', resp.data)
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          localStorage.removeItem('userID')
          reject(err)
        })
      })
  },
  checkRole({commit}){
    return  axios
      .post(apiURL+'/api/scripts-lists/checkRole')
       .then(res=>{
         commit('setRole', res.data.role)
         return res.data.role
       })
    },
  scriptList({commit,state}, ){
    return new Promise((resolve, reject) => {
      
      axios
      .get(this.state.apiURL + '/api/lessons',{
       headers: {
          Authorization: state.token,
        },
      } )
     
      .then(response => {
        commit('setScripts', response.data)

      })
    })
  

  },
  setScriptState({commit,state,dispatch},{id,status}){
    return   axios
        .get(apiURL+'/api/scripts-lists/'+id+'/changeStatus/'+status)
              .then(response => {
                return response.data.ok
              })
    
          
    },
  getScriptType({commit,state}, ){
    return new Promise((resolve, reject) => {
      
      axios
      .get(this.state.apiURL + '/api/script-types',{
        headers: {
          Authorization: state.token,
        },

      } )
     
      .then(response => {
        commit('setScriptTypes', response.data.data)

      })
    })
  

  },
  getScripts({commit,state},id ){
   
      
      axios
      .get(this.state.apiURL + '/api/lessons/aviableToAssing' ,{

       headers: {
          Authorization: state.token, 
        },
      } )
     
      .then(response => {
       
        commit('setScripts', response.data)

      })
    
  

  },
 

 

 
  async getStudents({commit,state},){
    //let token='Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTY1LCJpYXQiOjE2NzE5NzM4MjUsImV4cCI6MTY3NDU2NTgyNX0.2mHB879OgZGeVEFFLs0taR68wlKIepu0O7Kc0nenfdY'
   // localStorage.setItem('token', token)
   // axios.defaults.headers.common['Authorization'] =token
   await axios
    .post(this.state.apiURL + '/api/lessons/learners')
    .then(response => {  
      commit('setStudents', response.data.data)
        
    })

  },
  async assignToScripts({commit,state},{learners,scripts,difficult}){
    await axios
    .post(this.state.apiURL + '/api/lessons/assignToScripts',
      {
        data:{
          "learners" : learners,
          "scripts" : scripts,
          "difficult":difficult
        }
      })
      .then(res=>{
        commit("setSnackbar",{data:res.data[0]})
      })
    },
    async cancelAssign({commit,state},data){
     return await axios
      .post(this.state.apiURL + '/api/lessons/cancelAssign',
        {
          
          data
          
        })
        .then(res=>{
          //commit("setSnackbar",{data:res.data[0]})
        })
      },
      async  sendLessonLink({commit,state},data){
        return await axios
        .post(this.state.apiURL + '/api/lessons/sendLessonUrl',
          { 
            data
          })
          .then(res=>{
            commit("setSnackbar",{data:langMethods.methods.getLangConst('linkSent')})
          })
        },




    async  activate({commit,state,dispatch}, {data}){
      await axios
        .post(this.state.apiURL + '/api/lessons/activate',
          {
            data
          })
          .then(res=>{
            commit('setStudents', res.data)
          })

    },
    async  getActiveLessons({commit,state},{student,script}){
      let studentId=student?student:0
      let scriptId = script?script:0

      await axios
      .get(this.state.apiURL + '/api/lessons/'+scriptId+'/'+studentId+'/false/assignedScripts')
      .then(res=>{
        console.log(res);
        commit('setLessons', res.data)
      })
      
      
    },
    async addStudent({commit,state,dispatch},{fio,email}){
      
    
    
  return axios
    .post(this.state.apiURL + '/api/lessons/newLearner',{
      data:{
       "fio": fio,
       "email": email,
      }
      
   } )
   .then(response => {
     dispatch('getStudents')
     return response


   }).catch(ex=>{
    console.log(ex);
   })  
  },
  getReport({commit,state,dispatch},{reportName,options}){
      
   
    
   return axios
    .post(this.state.apiURL + '/api/lessons/reports',{
    /* headers: {
       Authorization:token,
      },*/
      data:{
        "report": reportName,
        "options": options,
      }
      
   } )
   .then(resp => {
   // console.log(resp.data);
     commit("setReport",resp.data)
     
    

   })  
  },
  getLesson({commit,state,dispatch},{id}){
      
   
    
    return axios
     .get(this.state.apiURL + '/api/lessons/'+id,{
     /* headers: {
        Authorization:token,
       },*/

       
    } )
    .then(resp => {
     console.log(resp.data);
      commit("setLessonData",resp.data)
      
     
 
    })  
   },


    

 

 


  register({commit}, user){
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios({url: apiURL + '/api/auth/register', data: user, method: 'POST' })
      .then(resp => {
        const token = resp.data.token
        const user = resp.data.user
        localStorage.setItem('token', token)
        axios.defaults.headers.common['Authorization'] = token
        commit('auth_success', token, user)
        resolve(resp)
      })
      .catch(err => {
        commit('auth_error', err)
        localStorage.removeItem('token')
        reject(err)
      })
    })
  },
 userInit({commit,dispatch},query ){
  localStorage.removeItem('token')
  delete axios.defaults.headers.common['Authorization']
  localStorage.removeItem('userID')
  let locale=query.locale
  let token=query.jw
  let page=query.page
  localStorage.setItem('token', token)
  localStorage.setItem('located', locale)
  axios.defaults.headers.common['Authorization'] = token

  commit('initSuccess', {token,locale})
  if(page){
    setTimeout(() => router.push('/'+page), 800); 
  }else{
    setTimeout(() => router.push('/'), 800); 
  }
  
/*
    return new Promise((resolve, reject) => {
     // commit('auth_request')

     axios
     .get(apiURL+'/api/auth/google/callback',{
      params:query
    })

      .then(res => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
        }
        return res;
      })
      .then(resp => {
        console.log(resp);
        const token = 'Bearer '+resp.data.jwt
          const user = resp.data.user
          localStorage.setItem('token', token)
          localStorage.setItem('userID', user.id)
          localStorage.setItem('username', user.username);
          axios.defaults.headers.common['Authorization'] = token
          commit('auth_success', resp.data)
          dispatch('checkRole')
          setTimeout(() => router.push('/'), 500); 

      })
      .catch(err => {
        commit('auth_error', err)
        localStorage.removeItem('token')
        reject(err)
      })
    })*/
  },
  
  logout({commit}){
    return new Promise((resolve) => {
      commit('logout')
      localStorage.removeItem('token')

      localStorage.removeItem('userID')
      delete axios.defaults.headers.common['Authorization']
      resolve()
    })
  }
  ,
  getSearchIDs({commit,state}, {searchStr}) {
    return new Promise((resolve, reject) => {
      axios
      .post(this.state.apiURL + '/api/scripts-lists/search',{
         headers: {
          Authorization: `Bearer ${state.token}`,
         },
         data:{
          "scriptID": state.script.id,
          "searchStr": searchStr,
        }   
      } )
      .then(response => {
        
        commit('setSearchIDs', response.data.data)

      })    

    });
  },
}
})