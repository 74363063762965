<template>
  <div id="app">
      <v-app :key="key">
        <v-app-bar
        v-if="$route.name!='init'"
       theme="dark"
      app
      height="48"
      color="#242D3F"
    >

       
          <div class="d-flex align-center pa-2">
         <a class="ma-2" :href="sUrl.indexOf('test')==-1?'https://editor.sonotiv.com/':'https://testeditor.sonotiv.com/'"> <v-img    width="30px" :src='require("@/assets/m.png")'></v-img></a>
           <v-icon class="ml-2" @click="showDrawer=!showDrawer" v-if="$vuetify.display.mobile">mdi-menu</v-icon>
          </div>
         
        <v-spacer></v-spacer>
        <template v-if="isLoggedIn&&$route.name=='dialog'" >





            <v-divider class="mx-2 " vertical></v-divider>
          </template>
        <!--  <v-btn @click="setLocated" style="text-transform:uppercase;">{{located}}</v-btn>-->

        <v-menu  v-if='isLoggedIn'  >
              <template v-slot:activator="{  props }">
              <!--  <v-btn color="#4863FF"  size="small" variant="icon" v-bind="props" icon="mdi-account-outline" >
                
                </v-btn>-->
                <v-avatar style="color:#fff;" v-bind="props"  icon="mdi-account-outline" size="30" class="ma-3 mr-5" color="#4863FF">
        
      </v-avatar>
              </template>
              <v-list theme="light" > 
                <!--<v-list-item width="200px" @click="$router.push({ path: `/account/` })">
                 <v-icon class=" mr-2">mdi-account-outline</v-icon> {{getLangConst('account')}}  
                </v-list-item>-->
                <v-list-item width="200px" @click="logout">
                    {{getLangConst('exit')}}  
                </v-list-item>
                
              </v-list>
            </v-menu>


    </v-app-bar>
    <v-main style="overflow-x:auto;"    >  
      <v-row class="ma-1">
        <v-col class="pr-0" :cols="!$vuetify.display.mobile?3:12">
      <div>
            <v-card
            v-if="($route.name!='LoginPage'&&$route.name!='init'&&!$vuetify.display.mobile)||($vuetify.display.mobile&&showDrawer)"
      class="pa-2 pr ml-5 mt-1 elevation-0 rounded-lg "
          floating
          permanent
        >
          <v-list
          v-if='isLoggedIn'
            density="compact"
            nav
          >
            <v-list-item class=" px-7 " to="/" @click="showDrawer=false" :class="$route.name=='Scripts'||$route.name=='ScriptCard'?'bg-primary':''"   value="home">
                    <v-list-item-title class="d-flex  align-center" >
                        <v-icon class="mr-3" >mdi-file-table-box-multiple-outline</v-icon>
                        {{getLangConst('scripts')}}
                    </v-list-item-title>


            </v-list-item>
            <v-list-item to="/students" class="px-7 " @click="showDrawer=false"  :class="$route.name=='Students'||$route.name=='StudentCard'?'bg-primary':''" value="students">
                      <v-list-item-title class="d-flex  align-center"  >
                        <v-icon class="mr-3"  >mdi-account-multiple-outline</v-icon>
                        {{getLangConst('students')}}
                    </v-list-item-title>

            </v-list-item>
             <v-list-item class="px-7"  to="/scriptsReport" @click="showDrawer=false"  :class="$route.name=='ScriptsReport'||$route.name=='ScriptCardReport'?'bg-primary':''"  value="scriptsReports">
                <v-list-item-title class="d-flex  align-center"  >
                        <v-icon class="mr-3"  >mdi-file-document-outline</v-icon>
                        {{getLangConst('scriptsReports')}}
                    </v-list-item-title>
             </v-list-item>
              <v-list-item class="px-7" to="/studentsReport" @click="showDrawer=false"  :class="$route.name=='StudentsReport'||$route.name=='StudentCardReport'?'bg-primary':''"  value="studentsReports">
                <v-list-item-title class="d-flex  align-center"  >
                        <v-icon class="mr-3"  >mdi-file-account-outline</v-icon>
                        {{getLangConst('studentsReports')}}
                    </v-list-item-title>

              </v-list-item>
          </v-list>
        </v-card>

 
 </div>
         </v-col>
   <v-col style="min-width:700px" class="pt-4 pr-5">
<router-view   ></router-view>
</v-col> 
   </v-row>
     </v-main> 
    
      <v-snackbar
      app
      class="mt-n5"
        v-model="snackbar.show"
        timeout="3000"
        :color="snackbar.color"
      >
        {{ snackbar.text }}
  

      </v-snackbar>
       
    </v-app>
  </div>
</template>
<script> 

export default { 
  name: "App",

   data() {
     return { 
      key:1,
      showDrawer:false
      };
     },
      computed : {
      sUrl(){return this.$store.getters.apiURL},
      role(){return this.$store.getters.getRole},
      isLoggedIn (){  return  this.$store.getters.isLoggedIn},
      located (){  return  this.$store.getters.getLocated},
      scriptName(){  return  this.$store.getters.getScriptName},
      snackbar(){ return this.$store.getters.snackbar}, 
      

      
    },
     
    methods: {
      setLocated(){
        let loc=this.located=='en'?'ru':'en'
        this.$store.commit('setLocated',loc)
         this.key++
      },
        checkCurrentRole(){
   if (this.role=='buyer'&&this.$route.name!='init'){
     window.location.href='https://editor.sonotiv.ru/account/?t=1'
   }
   if (this.role=='student'&&this.$route.name!='init'){
     window.location.href='https://sonotiv.com/'
   }
   },



      logout: function () {
        this.$store.dispatch('logout')
        .then(() => {
          window.location.href=this.sUrl.indexOf('test')==-1?'https://editor.sonotiv.com/login':'https://testeditor.sonotiv.com/login'
          //this.$router.push('/login')
        })
      },


    },
      mounted() {
        this.checkCurrentRole()
  },
   watch: {

    },
   };
 </script> 
 <style >
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
#app {

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
.v-tab--selected{
  color: #00d1ff!important;
}
.v-main{
  background: #f2f2f2;
}

.v-btn {
  font-weight:400 !important;
border-radius: 100px !important;
 text-transform: none!important;
font-style: normal;

letter-spacing: 0.0302857143em;
}
.v-btn--size-x-large{
  font-size: 1rem;
}
a {
  text-decoration: none;
}
select, input {
  background-color:'#4863ff1b';
  border: none;
}
.v-field--active  {
background-color:#4863ff1b;
border: none;

}


.v-field--focused div div:focus {
   outline:none;
}
.v-chip {
    border-radius: 8px;
}
.text-large{
  font-family: Roboto;
font-size: 25px;
font-weight: 500;
line-height: 40px;
}
.text-editor-card{

              position:fixed!important;
              height: calc(100vh - 48px) !important;
              top: 48px;
              bottom: 0px;
              right:0;
              width:30%;
}
.text-editor-card-onsearch{
                position:fixed!important;
              height: calc(100vh - 48px) !important;
              top: 48px;
              bottom: 0px;
              right:256px;
              width:calc(30% - (150px/2));
}

.text-small{

font-family: Roboto;
font-size: 24px;
font-weight: 400;
line-height: 32px;
letter-spacing: 0em;
text-align: center;

}
/*.v-field__input{
  border:none;
  max-height:40px;
}*/
.v-field__append-inner{
align-items: flex-start!important;
}
nav {
  padding: 30px;
}
  a {
    font-weight: bold;
    color: #4863FF;}

    .router-link-exact-active {
      color: #42b983;
    }
  

html{
  overflow-y: hidden;
}
.v-file-input .v-label.v-field-label{
  margin-top: -5px!important;

}
.dp__input{
  height:41px!important;
  border-color:#999;
  background-color: transparent;
  min-width: 150px;
}
html{
  overflow-y: scroll!important;
  font-weight:300!important;
  font-family: Inter, sans-serif !important;
}
#app {
  font-family: Inter, sans-serif !important
}

.v-file-input .v-label.v-field-label{
  margin-top: -9px!important;
  
}
.v-label v-field-label{
font-size: 13px;
  font-family: Inter, sans-serif !important
}
.v-field__field{
  font-size: 14px!important;  
  /*font-weight: 400!important;*/
}
.v-expansion-panel-text__wrapper {
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
 div::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

div::-webkit-scrollbar-track {
  /*box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
  background-color: #f9f9fd;*/
  border-radius: 10px;
  margin-bottom: 8px;
  margin-top: 8px;
}

div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #a3a3a3;
}

.lName {
  margin: 0; 
    -webkit-line-clamp: 2; 
    display: -webkit-box; 
    -webkit-box-orient: vertical; 
    overflow: hidden;
}
.v-card-text{
  font-size: 0.812rem;
}
.v-card-title{
  font-size: 18px;
}
.v-card-title{
  font-size: 18px;
}
.v-list-item-title{
font-size: 15px;
}
.v-list-item{
  padding: 4px 8px;
}
 
div{
   font-size: 0.814rem;
}
p{
   font-size: 0.814rem;
}
.s-13{
  font-size: 13px;
  font-family: Inter, sans-serif !important;
}
.s-15{
  font-size: 15px;
  font-family: Inter, sans-serif !important;
}
.s-20{
  font-size: 20px;
  font-family: Inter, sans-serif !important;
}

.v-list-item--variant-text .v-list-item__overlay {
    background: #000000;
    
}
.v-tooltip .v-overlay__content {
  background: #000000ce!important;
}
.v-select{
   --v-input-control-height: 8px!important;
   --v-input-padding-top: 3px!important;
    --v-input-padding-bottom: 5px!important;
}
.v-input{
   --v-input-control-height: 8px!important;
   --v-input-padding-top: 5px!important;
    --v-input-padding-bottom: 5px!important;
}
.v-input--density-default .v-field--variant-outlined {
 --v-field-padding-bottom: 3px!important;
}
.v-field__prepend-inner{
      display: flex;
      align-items: center !important;
      padding-bottom: 3px!important;
}
.v-field__append-inner {
   display: flex;
   align-items: center!important;
    padding-bottom: 3px!important;

}
.btn-excel{
  background-color:rgb(11, 110, 249);
  height: 35px;
  padding: 10px;
  color: antiquewhite;
}
</style>
