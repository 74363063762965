/* eslint-disable */
import { createWebHistory, createRouter } from "vue-router";
import store from '@/store/index.js'


const routes = [
/*  {
    path: "/login",
    name: "LoginPage",
    component: () => import("@/views/LoginPage.vue"),
  /*  meta: {
      requiresAuth: true,
    },
  },
 /* {
    path: "/about",
    name: "about",
    component: () => import("@/views/AboutView.vue"),
  
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/RegisterForm.vue"),

  },*/
  {
    path: "/",
    name: "Scripts",
    component: () => import("@/views/ScriptsView.vue"),
   meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/scriptCard/:id",
    name: "ScriptCard",
    component: () => import("@/components/ScriptCard.vue"),
   meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/studentCard/:id",
    name: "StudentCard",
    component: () => import("@/components/StudentCard.vue"),
   meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/studentsReport",
    name: "StudentsReport",
    component: () => import("@/views/StudentsReport.vue"),
   meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/students",
    name: "Students",
    component: () => import("@/views/StudentsView.vue"),
   meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/scriptsReport",
    name: "ScriptsReport",
    component: () => import("@/views/ScriptsReport.vue"),
   meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/scriptCardReport/:id",
    name: "ScriptCardReport",
    component: () => import("@/components/ScriptCard.vue"),
   meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/studentCardReport/:id",
    name: "StudentCardReport",
    component: () => import("@/components/StudentCard.vue"),
   meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/attempts/:id",
    name: "attempts",
    component: () => import("@/components/AttemptsCard.vue"),
   meta: {
      requiresAuth: true,
    },
  },


 

  {
    path: "/init",
    name: "init",
    component: () => import("@/views/initUser.vue"),
   meta: {
      requiresAuth: false,
    },
  },
  /*{
    path: "/:catchAll(.*)",
    beforeEnter: (to, from, next) => {
      let link =
        process.env.NODE_ENV === "production" ?
        to.fullPath :
        process.env.VUE_APP_WT_HOST + to.fullPath;
      let target = "_blank";

     

      next(false);
      //    abc.replace(/([^:]\/)\/+/g, "$1");
    },
  }*/


]

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    
    window.location.href=document.location.host.indexOf('test')==-1?'https://editor.sonotiv.com':'https://testeditor.sonotiv.com'
   // next() 
  } else {
    next() 
  }
})
export default router;