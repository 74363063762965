// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import * as labsComponents from 'vuetify/labs/components'
import * as components from 'vuetify/components'


export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  display: {
    mobileBreakpoint: 'md',
  },
  components: {
    ...components,
    ...labsComponents,
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#0b6ef9', // #E53935
            'primary-darken-1':'#21446E',
           // #FFCDD2
          
        }
      },
    },
  },

})
